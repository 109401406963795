import { Grind } from './_grind.js'

window.Vue = require('vue/dist/vue.common')

// var _ = require('lodash')
// var moment = require('moment')
// var axios = require('axios')

const app = new Vue ({
	el: '#application',
	delimiters: ['[[', ']]'],
	// components: { Multiselect, Datepicker },
	data: {
		image: [{ url: '/assets/img/front.jpg'}, { url: '/assets/img/back.jpg' }],
		imageCount: 0,
		page: 'Home',
		bg: 1,
		mobile: false,
		// page: 'Home'
	},
	computed: {
		
	},
	mounted: function() {
	
	},
	methods: {
		left: function() {
			if(this.imageCount > 0) {
				this.imageCount--
			}
		},
		right: function() {
			if(this.imageCount < 1) {
				this.imageCount++
			}
		},
		selector: function(page, bg) {
			this.page = page
			this.bg = bg
			this.mobile = false
		},
		mobileMenu: function() {
			console.log('test')
		}
	}
})